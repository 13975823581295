import React from "react";

import { ReInvent2022 } from "../../components/awsreinvent/ReInvent2022";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const ogImage = {
  ogImage:
    "https://res.cloudinary.com/dh8fp23nd/image/upload/v1667579594/main-web/og_aws_reinvet_2022_qjfmnz.jpg",
};

const canonicalUrl = "https://hasura.io/events/reinvent/";

const Security = props => (
  <Layout location={props.location}>
    <Seo
      title="Hasura at AWS Re:Invent 2022"
      description="Come visit us at the Hasura Booth #2251 at AWS Re:Invent and learn how GraphQL can benefit your organisation."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <ReInvent2022 location={props.location} />
  </Layout>
);

export default Security;
