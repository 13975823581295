import React from "react";
import { css } from "styled-components";

import { Box, Flex, Image } from "../../globals/UIKit";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import HeroBanner from "./common/HeroBanner";
import EventsList from "./common/EventsList";

const eventsListState = [
  {
    imgSrc: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1667465197/main-web/image_115_gcu1bz.png",
    imgAlt: "Connect with Hasura",
    imgMwd: "145px",
    title: "Connect with Hasura",
    desc: "Stop by our booth #2251 and grab some swag and enter our raffle!",
  },
  {
    imgSrc: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1667465197/main-web/image_113_a8jwk9.png",
    imgAlt: "Hasura Challenge @ the AWS Jam Lounge",
    imgMwd: "201px",
    title: <span>Hasura Challenge @<br />the AWS Jam Lounge</span>,
    desc: "Participate in our JAM challenge. You can stop by the AWS JAM Lounge and after you’ve completed our challenge, stop by for some very special swag!",
    rightIcon: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1667467934/main-web/icons/newTab_avsjfn.svg",
    linkText: "Learn more",
    linkUrl: "https://reinvent.awsevents.com/",
  },
  {
    imgSrc: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1667465197/main-web/image_114_k5jlge.png",
    imgAlt: "Party with Hasura",
    imgMwd: "135px",
    title: "Party with Hasura",
    desc: "Come hangout with Hasura, Cockroachlabs and LaunchDarkly on Tuesday, November 29th @ 6pm for networking over drinks & good food.",
    leftIcon: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1667466895/main-web/icons/Location_pin_vtgwpt.svg",
    linkText: "Mercato Della Pescheria",
    linkUrl: "https://www.cockroachlabs.com/events/aws-restaurant-reception-22/?utm_campaign=event-aws-reinvent-hasura-social-2023&utm_source=aws-reinvent-2022&utm_medium=event&utm_content",
  },
]

const EventMap = () => (
  <a
    href="https://awsreinvent22.mapyourshow.com/8_0/exhview/index.cfm?selectedBooth=2251"
    target="_blank"
    rel="noopener noreferrer"
  >
    <StyledContainerWrapper>
      <Flex
        width="100%"
        bg="#fff"
        padding="22px 19px"
        boxShadow="rgb(0 0 0 / 10%) 0px 1px 2px -1px, rgb(0 0 0 / 10%) 0px 1px 3px"
        borderRadius="8px"
        postion="relative"
        mb="70px"
        css={css`
          &:hover {
            box-shadow: rgb(0 0 0 / 10%) 0px 8px 10px -6px, rgb(0 0 0 / 10%) 0px 20px 25px -5px;
          }
        `}
      >
        <Image
          src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1667500031/main-web/Screenshot_2022-11-03_at_11.52.48_PM_mcmgnp.png"
          alt="Hasura booth"
          width="100%"
        />
      </Flex>
    </StyledContainerWrapper>
  </a>
);

export const ReInvent2022 = () => {
  return (
    <>
    <Box width="100%"
      css={css`
        background: linear-gradient(rgba(233, 239, 255, 0), #e9efff);
      `}
    >
      <HeroBanner
        title="Hasura at AWS re:Invent"
        subTitle="Meet us, compete in our JAM, enter our raffle & more"
        desc="Learn how you can get an instant GraphQL API on all your data by booking a demo with our team. Plus, pick up some cool new SWAG we’re rolling out :)"
        expo="See you at Booth #2251 in the expo hall"
        calendlyUrl="https://calendly.com/hasura-aws-re-invent/book-a-demo-at-aws-re-invent?month=2022-11"
        featureTitle="Here’s everything else we’ve got happening at re:Invent"
        heroImgSrc="https://res.cloudinary.com/dh8fp23nd/image/upload/v1667495421/main-web/Group_11563_1_c8mu7o.png"
        heroImgAlt="Hasura AWS ReInvent"
      />
      <EventsList eventsListState={eventsListState} />
      {/* <EventsList /> */}
    </Box>
    <Box width="100%"
      css={css`
        padding-top: 280px;
        background: #fff;
        @media (max-width: 780px) {

        }
      `}
    >
      <EventMap />
    </Box>
    </>
  );
};
